import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [0];

export const dictionary = {
		"/": [3],
		"/connector": [~7],
		"/connector/[actionType]/[[cuid]]": [~8],
		"/(policy)/cookies": [4,[2]],
		"/dataintegrity": [9],
		"/dataintegrity/add": [11],
		"/dataintegrity/delete/[cuid]": [12],
		"/dataintegrity/edit/[uid]": [13],
		"/dataintegrity/[uid]/history": [10],
		"/datatable": [~14],
		"/datatable/add": [15],
		"/(policy)/privacy": [5,[2]],
		"/project/actionType/[[puid]]": [~17],
		"/project/admin/add": [18],
		"/project/admin/edit/[pUID]": [19],
		"/project/admin/settings": [20],
		"/project/manage/[pUID]": [21],
		"/project/settings": [22],
		"/project/[[puid]]/[[actionType]]": [~16],
		"/(policy)/terms": [6,[2]],
		"/workflow": [23],
		"/workflow/add": [25],
		"/workflow/delete/[uid]": [26],
		"/workflow/edit/[uid]": [27],
		"/workflow/[uid]": [24]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';